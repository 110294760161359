import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import promotionReducer from './promotion/promotion';
import generalReducer from './general';

const rootConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [],
};

// const authConfig = {
//   key: 'auth',
//   version: 1,
//   storage: storage,
// };

const rootReducer = combineReducers({
  promotion: promotionReducer,
  general: generalReducer,
});

export default persistReducer(rootConfig, rootReducer);
