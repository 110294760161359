import { createSlice } from '@reduxjs/toolkit';

export const promotion = createSlice({
  name: 'promotion',
  initialState: {
    error: {},
    type: '',
    name: 'name',
    description: 'description',
    validFrom: '',
    validTo: '',
    active: false,
    orderItems: [],
    freeItems: [],
    conditions: [],
    priceTable: [],
    discountTable: [],
    applyTo: [{ code: ['CUSTOMERALL'], type: 'CUSTOMERALL' }],
  },
  reducers: {
    resetPromotion: (state, action) => {
      state._id = null;
      state.name = '';
      state.type = action.payload;
      state.description = '';
      state.validFrom = '';
      state.validTo = '';
      state.active = false;
      state.orderItems = [];
      state.freeItems = [];
      state.conditions = [];
      state.priceTable = [];
      state.discountTable = [];
      state.applyTo = [{ code: ['CUSTOMERALL'], type: 'CUSTOMERALL' }];
    },
    setPromotionValue: (state, action) => {
      console.log('promotion.setPromotionValue', action.payload);
      state[action.payload[0]] = action.payload[1];
    },
    delPromotionValueIndex: (state, action) => {
      console.log('promotion.delPromotionValueIndex', action.payload);
      delete state[action.payload[0]][action.payload[1]];
    },
    setPromotionValueIndex: (state, action) => {
      console.log('promotion.setPromotionValueIndex', action.payload);
      if (action.payload.length === 3) {
        Object.entries(action.payload[2]).forEach(([key, value]) => {
          state[action.payload[0]][action.payload[1]][key] = value;
        });
      }
      if (action.payload.length === 5) {
        Object.entries(action.payload[4]).forEach(([key, value]) => {
          state[action.payload[0]][action.payload[1]][action.payload[2]][action.payload[3]][key] = value;
        });
      }
    },
    addPromotionObj: (state, action) => {
      console.log('promotion.addPromotionObj', action.payload);
      state[action.payload[0]].push(action.payload[1]);
    },
    setPromotionObj: (state, action) => {
      console.log('promotion.setPromotionObj', action.payload);
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { resetPromotion, setPromotionValue, setPromotionValueIndex, setPromotionObj, addPromotionObj, delPromotionValueIndex } = promotion.actions;

export default promotion.reducer;
