import PropTypes from 'prop-types';

export function Logo(props) {
  const { emblemOnly, variant } = props;

  const color = variant === 'light' ? '#fff' : '#1e375f';

  const compactLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="33"
      height="33"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clip-compact-logo_1">
          <rect
            width="42"
            height="42"
          />
        </clipPath>
      </defs>
      <g
        id="compact-logo_1"
        data-name="compact-logo – 1"
        clipPath="url(#clip-compact-logo_1)"
      >
        <g
          id="Group_32"
          data-name="Group 32"
          transform="translate(39.06 -488.486)"
        >
          <g
            id="Group_22"
            data-name="Group 22"
            transform="translate(-39 488.486)"
          >
            <g
              id="Group_4"
              data-name="Group 4"
              transform="translate(0 0)"
            >
              <g
                id="Group_3"
                data-name="Group 3"
              >
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M17.418,24.3H16.99a.5.5,0,0,0-.49.551.54.54,0,0,0,.49.551h.429a.5.5,0,0,0,.49-.551.446.446,0,0,0-.49-.551"
                  transform="translate(-6.398 -12.912)"
                  fill={color}
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M20.251,31.5h.429a.551.551,0,1,0,0-1.1h-.429a.549.549,0,0,0-.551.551.586.586,0,0,0,.551.551"
                  transform="translate(-7.639 -15.278)"
                  fill={color}
                />
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M22.851,30.5a.551.551,0,1,0,0,1.1h3.122a.5.5,0,0,0,.49-.551.54.54,0,0,0-.49-.551Z"
                  transform="translate(-8.647 -15.316)"
                  fill={color}
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M28.108,27.99a.483.483,0,0,0-.49-.49H21.251a.54.54,0,0,0-.551.49.549.549,0,0,0,.551.551h6.306a.646.646,0,0,0,.551-.551"
                  transform="translate(-8.027 -14.153)"
                  fill={color}
                />
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M26,25.3a.551.551,0,1,0,0-1.1H19.69a.5.5,0,0,0-.49.551.54.54,0,0,0,.49.551Z"
                  transform="translate(-7.445 -12.873)"
                  fill={color}
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M37.143,43.5H20.49a.5.5,0,0,0-.49.551.483.483,0,0,0,.49.49H37.143a.54.54,0,0,0,.551-.49.549.549,0,0,0-.551-.551"
                  transform="translate(-7.755 -20.357)"
                  fill={color}
                />
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M39.488,28.441,38.569,27.4l-1.776-4.1a.485.485,0,0,0-.49-.306H34.345c.061-.367.122-.551.122-.612a.9.9,0,0,0-.735-.98H22.59a.5.5,0,0,0-.49.551.483.483,0,0,0,.49.49H33.427c-.122.857-.918,5.327-1.469,8.633H27.488a2.268,2.268,0,0,0-.49-1.1,1.533,1.533,0,0,0-1.1-.49h0a1.883,1.883,0,0,0-1.776,1.592h-.8c.122-.551.367-1.776.367-1.776a.528.528,0,1,0-1.041-.184c0,.061-.306,1.592-.367,1.959a.879.879,0,0,0,.673,1.041h1.224a1.684,1.684,0,0,0,.49.918,1.9,1.9,0,0,0,1.1.551h0a1.87,1.87,0,0,0,1.714-1.469h6.735a1.685,1.685,0,0,0,.49.918,1.9,1.9,0,0,0,1.1.551h0a1.87,1.87,0,0,0,1.714-1.469h1.041a.52.52,0,0,0,.49-.429l.612-2.755a.7.7,0,0,0-.184-.49m-13.837,4.1h0a.636.636,0,0,1-.49-.245,1.378,1.378,0,0,1-.306-.918c.061-.612.429-1.1.918-1.1a.636.636,0,0,1,.49.245,1.5,1.5,0,0,1,.306.918c-.061.612-.49,1.1-.918,1.1m10.041,0h0a.636.636,0,0,1-.49-.245,1.378,1.378,0,0,1-.306-.918c.061-.612.429-1.1.918-1.1a.756.756,0,0,1,.49.245,1.5,1.5,0,0,1,.306.918c-.061.612-.429,1.1-.918,1.1m2.388-1.531h-.612a2.268,2.268,0,0,0-.49-1.1,1.533,1.533,0,0,0-1.1-.49h0A1.883,1.883,0,0,0,34.1,31.012H33c.245-1.469.857-4.9,1.224-6.98H36l1.653,3.918a.132.132,0,0,0,.122.122l.8.918Z"
                  transform="translate(-8.569 -11.788)"
                  fill={color}
                />
                <g
                  id="Group_2"
                  data-name="Group 2"
                >
                  <g
                    id="Group_1"
                    data-name="Group 1"
                  >
                    <path
                      id="Path_8"
                      data-name="Path 8"
                      d="M36.673,5.7H5.265A5.318,5.318,0,0,0,0,11.027V42.435A5.265,5.265,0,0,0,5.265,47.7H36.673a5.265,5.265,0,0,0,5.265-5.265V11.027A5.24,5.24,0,0,0,36.673,5.7M21.8,44.7a.987.987,0,0,1-.8.367.965.965,0,0,1-.8-.367c-2.143-2.265-12.98-14.082-12.98-22.592a13.806,13.806,0,0,1,27.612,0C34.837,30.618,24,42.373,21.8,44.7"
                      transform="translate(0 -5.7)"
                      fill={color}
                    />
                  </g>
                </g>
              </g>
            </g>

          </g>
        </g>
      </g>
    </svg>
  );

  const completeLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="146"
      height="42"
      viewBox="0 0 146 42"
    >
      <defs>
        <clipPath id="clip-Vansale-logo">
          <rect
            width="146"
            height="42"
          />
        </clipPath>
      </defs>
      <g
        id="Vansale-logo"
        clipPath="url(#clip-Vansale-logo)"
      >
        <g
          id="Group_28"
          data-name="Group 28"
          transform="translate(-315.823 -122.364)"
        >
          <g
            id="Group_22"
            data-name="Group 22"
            transform="translate(316 125)"
          >
            <g
              id="Group_4"
              data-name="Group 4"
              transform="translate(0 2.818)"
            >
              <g
                id="Group_3"
                data-name="Group 3"
              >
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M17.241,24.3H16.9a.4.4,0,0,0-.4.445.436.436,0,0,0,.4.445h.346a.4.4,0,0,0,.4-.445.36.36,0,0,0-.4-.445"
                  transform="translate(-8.344 -15.106)"
                  fill={color}
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M20.145,31.29h.346a.445.445,0,0,0,0-.89h-.346a.443.443,0,0,0-.445.445.474.474,0,0,0,.445.445"
                  transform="translate(-9.962 -18.19)"
                  fill={color}
                />
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M22.745,30.5a.445.445,0,0,0,0,.89h2.521a.4.4,0,0,0,.4-.445.436.436,0,0,0-.4-.445Z"
                  transform="translate(-11.277 -18.241)"
                  fill={color}
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M26.681,27.9a.39.39,0,0,0-.4-.4H21.145a.436.436,0,0,0-.445.4.443.443,0,0,0,.445.445h5.091a.522.522,0,0,0,.445-.445"
                  transform="translate(-10.468 -16.724)"
                  fill={color}
                />
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M24.687,25.09a.445.445,0,0,0,0-.89H19.6a.4.4,0,0,0-.4.445.436.436,0,0,0,.4.445Z"
                  transform="translate(-9.709 -15.055)"
                  fill={color}
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M33.841,43.5H20.4a.4.4,0,0,0-.4.445.39.39,0,0,0,.4.4H33.841a.436.436,0,0,0,.445-.4.443.443,0,0,0-.445-.445"
                  transform="translate(-10.114 -24.815)"
                  fill={color}
                />
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M36.139,27.085l-.741-.84-1.434-3.312a.391.391,0,0,0-.4-.247H31.986c.049-.3.1-.445.1-.494a.727.727,0,0,0-.593-.791h-9a.4.4,0,0,0-.4.445.39.39,0,0,0,.4.4h8.749c-.1.692-.741,4.3-1.186,6.97H26.45a1.831,1.831,0,0,0-.4-.89,1.238,1.238,0,0,0-.89-.4h0a1.521,1.521,0,0,0-1.434,1.285h-.643c.1-.445.3-1.434.3-1.434a.427.427,0,1,0-.84-.148c0,.049-.247,1.285-.3,1.582a.71.71,0,0,0,.544.84h.989a1.36,1.36,0,0,0,.4.741,1.534,1.534,0,0,0,.89.445h0a1.51,1.51,0,0,0,1.384-1.186h5.437a1.36,1.36,0,0,0,.4.741,1.534,1.534,0,0,0,.89.445h0a1.51,1.51,0,0,0,1.384-1.186h.84a.419.419,0,0,0,.4-.346l.494-2.224a.561.561,0,0,0-.148-.4M24.967,30.4h0a.514.514,0,0,1-.4-.2,1.112,1.112,0,0,1-.247-.741c.049-.494.346-.89.741-.89a.514.514,0,0,1,.4.2,1.214,1.214,0,0,1,.247.741c-.049.494-.4.89-.741.89m8.107,0h0a.514.514,0,0,1-.4-.2,1.112,1.112,0,0,1-.247-.741c.049-.494.346-.89.741-.89a.61.61,0,0,1,.4.2,1.214,1.214,0,0,1,.247.741c-.049.494-.346.89-.741.89M35,29.161h-.494a1.831,1.831,0,0,0-.4-.89,1.238,1.238,0,0,0-.89-.4h0a1.521,1.521,0,0,0-1.434,1.285H30.9c.2-1.186.692-3.955.989-5.635h1.434l1.335,3.164a.106.106,0,0,0,.1.1l.643.741Z"
                  transform="translate(-11.176 -13.639)"
                  fill={color}
                />
                <g
                  id="Group_2"
                  data-name="Group 2"
                >
                  <g
                    id="Group_1"
                    data-name="Group 1"
                  >
                    <path
                      id="Path_8"
                      data-name="Path 8"
                      d="M29.61,5.7H4.251A4.293,4.293,0,0,0,0,10V35.359A4.251,4.251,0,0,0,4.251,39.61H29.61a4.251,4.251,0,0,0,4.251-4.251V10A4.231,4.231,0,0,0,29.61,5.7M17.6,37.188a.8.8,0,0,1-.643.3.779.779,0,0,1-.643-.3c-1.73-1.829-10.48-11.369-10.48-18.24a11.147,11.147,0,0,1,22.294,0c0,6.871-8.749,16.362-10.529,18.24"
                      transform="translate(0 -5.7)"
                      fill={color}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="Group_21"
              data-name="Group 21"
              transform="translate(39.397)"
            >
              <g
                id="Group_20"
                data-name="Group 20"
              >
                <g
                  id="Group_5"
                  data-name="Group 5"
                  transform="translate(0 5.388)"
                >
                  <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M88.351,24.494,93.541,10.9H89.586L86.62,19.4l-2.966-8.5H79.7l5.19,13.594Z"
                    transform="translate(-79.7 -10.9)"
                    fill={color}
                  />
                </g>
                <g
                  id="Group_7"
                  data-name="Group 7"
                  transform="translate(79.931 4.894)"
                >
                  <g
                    id="Group_6"
                    data-name="Group 6"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M243.526,22.258a6.751,6.751,0,0,0,2.224,1.483,7.022,7.022,0,0,0,2.719.544,6.509,6.509,0,0,0,1.681-.2,8.2,8.2,0,0,0,1.532-.593,5.016,5.016,0,0,0,1.335-.939,6.5,6.5,0,0,0,1.087-1.236l-2.521-2.57a3.06,3.06,0,0,1-.544.84,3.159,3.159,0,0,1-.741.643,4.855,4.855,0,0,1-.89.4,3.187,3.187,0,0,1-.989.148h-.4a1.164,1.164,0,0,1-.4-.1l6.476-7.81a6.754,6.754,0,0,0-1.137-1.236,6.224,6.224,0,0,0-1.384-.939,8.2,8.2,0,0,0-1.532-.593,6.264,6.264,0,0,0-1.681-.2,8.281,8.281,0,0,0-2.719.494,6.75,6.75,0,0,0-2.224,1.483,6.477,6.477,0,0,0-1.483,2.274,8.122,8.122,0,0,0-.544,2.966,7.6,7.6,0,0,0,.544,2.867,8.479,8.479,0,0,0,1.582,2.274m1.73-6.673A3.7,3.7,0,0,1,246,14.4a4.229,4.229,0,0,1,1.087-.741,2.928,2.928,0,0,1,1.335-.247h.494a2.176,2.176,0,0,1,.494.1l-4.053,5.339a3.4,3.4,0,0,1-.148-.4,1.462,1.462,0,0,1-.1-.494,1.681,1.681,0,0,1-.049-.494v-.445a2.822,2.822,0,0,1,.2-1.434"
                      transform="translate(-241.4 -9.9)"
                      fill={color}
                    />
                  </g>
                </g>
                <g
                  id="Group_8"
                  data-name="Group 8"
                  transform="translate(93.871 5.437)"
                >
                  <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M279.239,24.248a4.447,4.447,0,0,0,2.323-2.323,3.941,3.941,0,0,0,.346-1.681,3.582,3.582,0,0,0-.346-1.631,4.114,4.114,0,0,0-.939-1.285,4.326,4.326,0,0,0-1.384-.84,4.814,4.814,0,0,0-1.681-.3H273.95a.819.819,0,0,1-.593-.247.94.94,0,0,1-.247-.643.819.819,0,0,1,.247-.593.912.912,0,0,1,.593-.247h7.019V11H273.95a3.793,3.793,0,0,0-1.681.346,4.447,4.447,0,0,0-2.323,2.323,3.942,3.942,0,0,0-.346,1.681,3.794,3.794,0,0,0,.346,1.681,3.824,3.824,0,0,0,.939,1.384,4.565,4.565,0,0,0,1.384.939,3.941,3.941,0,0,0,1.681.346h3.608a1.844,1.844,0,0,1,.593.1.546.546,0,0,1,.247.494.819.819,0,0,1-.247.593,1.027,1.027,0,0,1-.593.247h-7.909v3.46h7.909a6.352,6.352,0,0,0,1.681-.346"
                    transform="translate(-269.6 -11)"
                    fill={color}
                  />
                </g>
                <g
                  id="Group_19"
                  data-name="Group 19"
                  transform="translate(2.57)"
                >
                  <g
                    id="Group_9"
                    data-name="Group 9"
                    transform="translate(72.022)"
                  >
                    <path
                      id="Path_12"
                      data-name="Path 12"
                      d="M234.11,0,230.6,4.7V18.982h3.51Z"
                      transform="translate(-230.6)"
                      fill={color}
                    />
                  </g>
                  <g
                    id="Group_10"
                    data-name="Group 10"
                    transform="translate(0.148 24.024)"
                  >
                    <rect
                      id="Rectangle_1"
                      data-name="Rectangle 1"
                      width="103.411"
                      height="1.137"
                      fill={color}
                    />
                  </g>
                  <g
                    id="Group_12"
                    data-name="Group 12"
                    transform="translate(11.419 4.943)"
                  >
                    <g
                      id="Group_11"
                      data-name="Group 11"
                    >
                      <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M110.076,22.407a6.488,6.488,0,0,0,2.224,1.384,7,7,0,0,0,2.719.494,5.388,5.388,0,0,0,1.285-.148,5.259,5.259,0,0,0,1.236-.445A5.381,5.381,0,0,0,118.677,23a8.565,8.565,0,0,0,1.038-.84l1.335,1.878h.84V10.494h-.84l-1.335,1.631a8.564,8.564,0,0,0-1.038-.84,6.806,6.806,0,0,0-1.186-.692,6.2,6.2,0,0,0-1.236-.445A6.039,6.039,0,0,0,114.97,10a7.211,7.211,0,0,0-2.719.544,6.751,6.751,0,0,0-2.224,1.483,6.477,6.477,0,0,0-1.483,2.274A8.052,8.052,0,0,0,108,17.217a8.2,8.2,0,0,0,.544,3.015,7.815,7.815,0,0,0,1.532,2.175m1.73-6.822a3.774,3.774,0,0,1,.741-1.137,3.142,3.142,0,0,1,1.087-.692,4.238,4.238,0,0,1,1.335-.247,2.943,2.943,0,0,1,1.335.3,3.065,3.065,0,0,1,1.087.84,4.813,4.813,0,0,1,.741,1.186,3.279,3.279,0,0,1,.247,1.384,4.288,4.288,0,0,1-.247,1.384,4.813,4.813,0,0,1-.741,1.186,3.574,3.574,0,0,1-1.087.84,3.1,3.1,0,0,1-1.335.3,3.774,3.774,0,0,1-1.335-.247,3.142,3.142,0,0,1-1.087-.692,3.774,3.774,0,0,1-.741-1.137,3.944,3.944,0,0,1-.247-1.582,5.25,5.25,0,0,1,.247-1.681"
                        transform="translate(-108 -10)"
                        fill={color}
                      />
                    </g>
                  </g>
                  <g
                    id="Group_13"
                    data-name="Group 13"
                    transform="translate(27.929 5.141)"
                  >
                    <path
                      id="Path_14"
                      data-name="Path 14"
                      d="M144.91,16.48a2.213,2.213,0,0,1,.2-.989,3.06,3.06,0,0,1,.544-.84,1.907,1.907,0,0,1,.84-.544,2.431,2.431,0,0,1,1.038-.2,2.213,2.213,0,0,1,.989.2,3.06,3.06,0,0,1,.84.544,1.907,1.907,0,0,1,.544.84,2.213,2.213,0,0,1,.2.989v7.761h3.46V16.48a5.523,5.523,0,0,0-.494-2.373,5.907,5.907,0,0,0-3.213-3.213,6.363,6.363,0,0,0-2.373-.494,6.8,6.8,0,0,0-2.224.4,5.764,5.764,0,0,0-1.878,1.186l-1.137-1.335h-.84V24.241h3.46V16.48Z"
                      transform="translate(-141.4 -10.4)"
                      fill={color}
                    />
                  </g>
                  <g
                    id="Group_14"
                    data-name="Group 14"
                    transform="translate(42.116 5.437)"
                  >
                    <path
                      id="Path_15"
                      data-name="Path 15"
                      d="M179.739,24.248a4.447,4.447,0,0,0,2.323-2.323,3.941,3.941,0,0,0,.346-1.681,3.582,3.582,0,0,0-.346-1.631,4.114,4.114,0,0,0-.939-1.285,4.326,4.326,0,0,0-1.384-.84,4.813,4.813,0,0,0-1.681-.3H174.45a.819.819,0,0,1-.593-.247.94.94,0,0,1-.247-.643.819.819,0,0,1,.247-.593.912.912,0,0,1,.593-.247h7.019V11H174.45a3.793,3.793,0,0,0-1.681.346,4.447,4.447,0,0,0-2.323,2.323,3.941,3.941,0,0,0-.346,1.681,3.794,3.794,0,0,0,.346,1.681,4.447,4.447,0,0,0,2.323,2.323,3.941,3.941,0,0,0,1.681.346h3.609a1.844,1.844,0,0,1,.593.1.545.545,0,0,1,.247.494.819.819,0,0,1-.247.593.912.912,0,0,1-.593.247h-7.909v3.46h7.909a6.352,6.352,0,0,0,1.681-.346"
                      transform="translate(-170.1 -11)"
                      fill={color}
                    />
                  </g>
                  <g
                    id="Group_16"
                    data-name="Group 16"
                    transform="translate(55.462 4.943)"
                  >
                    <g
                      id="Group_15"
                      data-name="Group 15"
                    >
                      <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M199.176,22.407a6.488,6.488,0,0,0,2.224,1.384,7,7,0,0,0,2.719.494,5.388,5.388,0,0,0,1.285-.148,5.259,5.259,0,0,0,1.236-.445A5.381,5.381,0,0,0,207.777,23a8.566,8.566,0,0,0,1.038-.84l1.335,1.878h.84V10.494h-.84l-1.335,1.631a5.924,5.924,0,0,0-1.038-.84,6.807,6.807,0,0,0-1.186-.692,6.2,6.2,0,0,0-1.236-.445A6.038,6.038,0,0,0,204.07,10a7.21,7.21,0,0,0-2.719.544,6.75,6.75,0,0,0-2.224,1.483,6.477,6.477,0,0,0-1.483,2.274,8.052,8.052,0,0,0-.544,2.916,8.2,8.2,0,0,0,.544,3.015,6.914,6.914,0,0,0,1.532,2.175m1.73-6.822a3.774,3.774,0,0,1,.741-1.137,3.142,3.142,0,0,1,1.087-.692,4.238,4.238,0,0,1,1.335-.247,2.943,2.943,0,0,1,1.335.3,3.575,3.575,0,0,1,1.087.84,4.813,4.813,0,0,1,.741,1.186,3.279,3.279,0,0,1,.247,1.384,4.288,4.288,0,0,1-.247,1.384,4.813,4.813,0,0,1-.741,1.186,3.575,3.575,0,0,1-1.087.84,2.943,2.943,0,0,1-1.335.3,3.774,3.774,0,0,1-1.335-.247,3.142,3.142,0,0,1-1.087-.692,3.774,3.774,0,0,1-.741-1.137,3.944,3.944,0,0,1-.247-1.582,5.25,5.25,0,0,1,.247-1.681"
                        transform="translate(-197.1 -10)"
                        fill={color}
                      />
                    </g>
                  </g>
                  <g
                    id="Group_18"
                    data-name="Group 18"
                    transform="translate(0 29.165)"
                  >
                    <g
                      id="Group_17"
                      data-name="Group 17"
                    >
                      <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M85.048,63.844a2.61,2.61,0,0,0,1.285.346c.741,0,1.186-.4,1.186-.939s-.3-.84-1.038-1.137C85.543,61.768,85,61.323,85,60.532A1.627,1.627,0,0,1,86.828,59a2.362,2.362,0,0,1,1.236.3l-.2.593a2.323,2.323,0,0,0-1.088-.247c-.791,0-1.038.445-1.038.84,0,.544.346.791,1.137,1.087.939.346,1.434.84,1.434,1.631,0,.89-.643,1.631-1.977,1.631a2.917,2.917,0,0,1-1.434-.346Z"
                        transform="translate(-84.9 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M94.235,63.056l-.593,1.78H92.9L94.828,59.2h.89l1.928,5.635h-.791l-.643-1.78Zm1.829-.544-.544-1.631a8.181,8.181,0,0,1-.3-1.038h0l-.3,1.038-.544,1.631Z"
                        transform="translate(-88.945 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M104.1,59.2h.741v5.042h2.422v.593h-3.114V59.2Z"
                        transform="translate(-94.609 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M115.016,62.215h-2.175v2.027h2.422v.593H112.1V59.2h3.015v.593h-2.323v1.78h2.175v.643Z"
                        transform="translate(-98.655 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M120.048,63.844a2.61,2.61,0,0,0,1.285.346c.741,0,1.186-.4,1.186-.939s-.3-.84-1.038-1.137c-.939-.346-1.483-.791-1.483-1.582A1.627,1.627,0,0,1,121.828,59a2.362,2.362,0,0,1,1.236.3l-.2.593a2.323,2.323,0,0,0-1.088-.247c-.791,0-1.038.445-1.038.84,0,.544.346.791,1.137,1.087.939.346,1.434.84,1.434,1.631,0,.89-.643,1.631-1.977,1.631a2.917,2.917,0,0,1-1.434-.346Z"
                        transform="translate(-102.599 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M135.405,64.734l-.544-.544a2.359,2.359,0,0,1-1.631.643,1.6,1.6,0,0,1-1.73-1.582,1.786,1.786,0,0,1,1.087-1.631V61.57a1.867,1.867,0,0,1-.445-1.186A1.362,1.362,0,0,1,133.576,59a1.155,1.155,0,0,1,1.236,1.186c0,.593-.346,1.038-1.236,1.483v.049c.494.544.989,1.186,1.384,1.582a4.2,4.2,0,0,0,.544-1.631h.692a4.457,4.457,0,0,1-.791,2.076c.3.3.593.643.939.989Zm-.89-.989c-.346-.346-.939-1.038-1.582-1.78a1.4,1.4,0,0,0-.741,1.137,1.119,1.119,0,0,0,1.137,1.137A1.344,1.344,0,0,0,134.515,63.745Zm-1.681-3.411a1.523,1.523,0,0,0,.445.989c.544-.346.939-.643.939-1.088a.676.676,0,0,0-.643-.741A.754.754,0,0,0,132.835,60.335Z"
                        transform="translate(-108.465 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M146.1,59.3a14.093,14.093,0,0,1,1.532-.1,3.289,3.289,0,0,1,2.274.692,2.563,2.563,0,0,1,.791,2.027,2.994,2.994,0,0,1-.791,2.175,3.38,3.38,0,0,1-2.472.791c-.494,0-.939-.049-1.335-.049Zm.741,4.943a3.273,3.273,0,0,0,.741.049,2.16,2.16,0,0,0,2.373-2.373,2.008,2.008,0,0,0-2.274-2.175,3.225,3.225,0,0,0-.84.1Z"
                        transform="translate(-115.848 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M158.041,59.2v5.635H157.3V59.2Z"
                        transform="translate(-121.511 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M161.048,63.844a2.61,2.61,0,0,0,1.285.346c.741,0,1.186-.4,1.186-.939s-.3-.84-1.038-1.137c-.939-.346-1.483-.791-1.483-1.582A1.627,1.627,0,0,1,162.828,59a2.362,2.362,0,0,1,1.236.3l-.2.593a2.323,2.323,0,0,0-1.087-.247c-.791,0-1.038.445-1.038.84,0,.544.346.791,1.137,1.087.939.346,1.434.84,1.434,1.631,0,.89-.643,1.631-1.977,1.631a2.917,2.917,0,0,1-1.434-.346Z"
                        transform="translate(-123.332 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M170.13,59.843H168.4V59.2h4.152v.643h-1.73v4.993h-.741V59.843Z"
                        transform="translate(-127.125 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M178.049,59.3a5.865,5.865,0,0,1,1.384-.1,2.385,2.385,0,0,1,1.631.445,1.38,1.38,0,0,1,.445,1.087,1.455,1.455,0,0,1-1.038,1.434v.049a1.408,1.408,0,0,1,.84,1.137,6.682,6.682,0,0,0,.445,1.532h-.791a9.885,9.885,0,0,1-.4-1.335c-.148-.791-.445-1.087-1.137-1.087h-.692v2.422H178V59.3Zm.741,2.57h.741c.791,0,1.285-.445,1.285-1.087,0-.741-.544-1.038-1.285-1.038-.346,0-.593.049-.741.049Z"
                        transform="translate(-131.979 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M187.941,59.2v5.635H187.2V59.2Z"
                        transform="translate(-136.631 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M191.249,59.3a9.245,9.245,0,0,1,1.335-.1,2.478,2.478,0,0,1,1.532.4,1.118,1.118,0,0,1,.445.989,1.3,1.3,0,0,1-.939,1.236h0a1.457,1.457,0,0,1,1.137,1.434,1.524,1.524,0,0,1-.494,1.137,2.9,2.9,0,0,1-1.928.544c-.494,0-.89-.049-1.137-.049V59.3Zm.692,2.274h.643c.791,0,1.236-.4,1.236-.939,0-.643-.494-.939-1.236-.939-.346,0-.544.049-.643.049Zm0,2.719a2.037,2.037,0,0,0,.593.049c.741,0,1.434-.3,1.434-1.087s-.643-1.088-1.434-1.088h-.593Z"
                        transform="translate(-138.654 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M201.192,59.2v3.361c0,1.285.544,1.78,1.335,1.78.84,0,1.384-.544,1.384-1.78V59.249h.741v3.262c0,1.73-.89,2.422-2.126,2.422-1.137,0-2.027-.643-2.027-2.422V59.2Z"
                        transform="translate(-143.357 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M211.73,59.843H210V59.2h4.152v.643h-1.681v4.993h-.741Z"
                        transform="translate(-148.161 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_32"
                        data-name="Path 32"
                        d="M220.441,59.2v5.635H219.7V59.2Z"
                        transform="translate(-153.066 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_33"
                        data-name="Path 33"
                        d="M228.241,61.867a2.648,2.648,0,0,1-2.62,2.966,2.571,2.571,0,0,1-2.521-2.867A2.674,2.674,0,0,1,225.72,59,2.57,2.57,0,0,1,228.241,61.867Zm-4.35.1c0,1.186.643,2.274,1.78,2.274s1.78-1.038,1.78-2.323c0-1.137-.593-2.274-1.78-2.274C224.484,59.593,223.891,60.73,223.891,61.966Z"
                        transform="translate(-154.785 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M235.4,64.835V59.2h.791l1.829,2.867a13.471,13.471,0,0,1,.989,1.829h0c-.049-.741-.1-1.434-.1-2.323V59.2h.692v5.635h-.741l-1.78-2.867a14.5,14.5,0,0,1-1.038-1.878h-.049c.049.692.049,1.384.049,2.323v2.422Z"
                        transform="translate(-161.005 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M249.83,59.843H248.1V59.2h4.152v.643h-1.73v4.993h-.741V59.843Z"
                        transform="translate(-167.427 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M261.841,61.867a2.648,2.648,0,0,1-2.62,2.966,2.571,2.571,0,0,1-2.521-2.867A2.674,2.674,0,0,1,259.32,59,2.57,2.57,0,0,1,261.841,61.867Zm-4.35.1c0,1.186.643,2.274,1.78,2.274s1.78-1.038,1.78-2.323c0-1.137-.593-2.274-1.78-2.274C258.084,59.593,257.491,60.73,257.491,61.966Z"
                        transform="translate(-171.776 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M273.441,61.867a2.648,2.648,0,0,1-2.62,2.966,2.571,2.571,0,0,1-2.521-2.867A2.674,2.674,0,0,1,270.92,59,2.57,2.57,0,0,1,273.441,61.867Zm-4.35.1c0,1.186.643,2.274,1.78,2.274s1.78-1.038,1.78-2.323c0-1.137-.593-2.274-1.78-2.274C269.684,59.593,269.091,60.73,269.091,61.966Z"
                        transform="translate(-177.642 -59)"
                        fill={color}
                      />
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M280.7,59.2h.741v5.042h2.422v.593h-3.114V59.2Z"
                        transform="translate(-183.913 -59.101)"
                        fill={color}
                      />
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M288.248,63.844a2.609,2.609,0,0,0,1.285.346c.741,0,1.186-.4,1.186-.939s-.3-.84-1.038-1.137c-.939-.346-1.483-.791-1.483-1.582A1.627,1.627,0,0,1,290.028,59a2.362,2.362,0,0,1,1.236.3l-.2.593a2.323,2.323,0,0,0-1.087-.247c-.791,0-1.038.445-1.038.84,0,.544.346.791,1.137,1.087.939.346,1.434.84,1.434,1.631,0,.89-.643,1.631-1.977,1.631a2.917,2.917,0,0,1-1.434-.346Z"
                        transform="translate(-187.655 -59)"
                        fill={color}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  return (
    !emblemOnly ? (
      <div>
        {compactLogo}
      </div>
    ) : (
      <div>
        {completeLogo}
      </div>
    )
  );
}

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark'
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark'])
};
