import axios from 'axios';
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { uriConfig } from '../config';
import { authApi } from '../api/auth';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  sso: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initialize = async () => {
      try {
        let accessToken = window.localStorage.getItem('accessToken');
        const refreshToken = window.localStorage.getItem('refreshToken');
        const ssoToken = (searchParams.get('ssoToken'));
        const clientId = (searchParams.get('clientId'));
        // console.log(`accessToken ${accessToken}`);
        // console.log(`refreshToken ${refreshToken}`);
        // console.log(`ssoToken ${ssoToken}`);
        // console.log(`clientId ${clientId}`);
        if (accessToken) {
          let user = await authApi.me(accessToken);
          console.log('accessToken');
          if (!user) {
            window.localStorage.removeItem('accessToken');
            console.log('accessToken>refreshToken');
            user = await authApi.refreshToken(refreshToken);
            if (!user) {
              console.log('accessToken>refreshToken>sso');
              accessToken = await authApi.sso({ clientId, ssoToken });
              user = await authApi.me(accessToken);
            }
          }
          if (!user) {
            window.localStorage.removeItem('refreshToken');
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const sso = async (clientId, ssoToken) => {
    const SERVICE_URI = uriConfig.uriService;
    const accessToken = await authApi.sso({ clientId, ssoToken });
    const responseUser = await axios.get(`${SERVICE_URI}/api/v1/me`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    console.log('responseUser');
    console.log(responseUser.data);

    dispatch({
      type: 'LOGIN',
      payload: {
        responseUser
      }
    });
  };

  const login = async (email, password) => {
    // const accessToken = await authApi.login({ email, password });
    const SERVICE_URI = uriConfig.uriService;
    console.log(`context ${SERVICE_URI}/api/v1/login | ${email} | ${password} `);
    const response = await axios.post(`${SERVICE_URI}/api/v1/login`, {}, {
      auth: {
        username: email,
        password
      }
    });
    console.log(response);
    localStorage.setItem('refreshToken', response.headers.refreshToken);

    const responseUser = await axios.get(`${SERVICE_URI}/api/v1/me`, {
      headers: {
        Authorization: `Bearer ${response.data.token}`
      }
    });
    console.log('responseUser');
    console.log(responseUser.data);

    const responseCompany = await axios.get(`${SERVICE_URI}/api/v1/currentCompany`, {
      headers: {
        Authorization: `Bearer ${response.data.token}`
      }
    });
    console.log('responseCompany');
    console.log(responseCompany.data);

    localStorage.setItem('accessToken', response.data.token);
    localStorage.setItem('clientId', responseCompany.data.data.clientId);

    dispatch({
      type: 'LOGIN',
      payload: {
        responseUser
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        sso,
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
