import { createSlice } from '@reduxjs/toolkit';

export const general = createSlice({
  name: 'general',
  initialState: {
    name: 'general'
  },
  reducers: {
    reset: (state) => {
      Object.keys(state).forEach((key) => {
        if (key !== 'name') delete state[key];
      });
    },
    setValue: (state, action) => {
      console.log('setValue', action.payload);
      state[action.payload[0]] = action.payload[1];
    },
    setValueIndex: (state, action) => {
      console.log('setValueIndex', action.payload);
      if (action.payload.length === 3) {
        Object.entries(action.payload[2]).forEach(([key, value]) => {
          state[action.payload[0]][action.payload[1]][key] = value;
        });
      }
      if (action.payload.length === 5) {
        Object.entries(action.payload[4]).forEach(([key, value]) => {
          state[action.payload[0]][action.payload[1]][action.payload[2]][action.payload[3]][key] = value;
        });
      }
    },
    addObj: (state, action) => {
      console.log('addObj', action.payload);
      state[action.payload[0]].push(action.payload[1]);
    },
    setObj: (state, action) => {
      console.log('setObj', action.payload);
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { reset, setValue, setValueIndex, setObj, addObj } = general.actions;

export default general.reducer;
