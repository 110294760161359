import axios from 'axios';
import { uriConfig } from '../config';
import { generateResourceId } from '../utils/generate-resource-id';
import { sign, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt';

const users = [
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/user-chen_simmons.png',
    email: 'demo@devias.io',
    name: 'Chen Simmons',
    password: 'Password123!'
  }
];

class AuthApi {
  async sso({ clientId, ssoToken }) {
    const SERVICE_URI = uriConfig.uriService;
    const response = await axios.post(`${SERVICE_URI}/api/v1/${clientId}/auth/sso?token=${ssoToken}`, {}, {
    }).catch((error) => {
      console.log(error);
    });
    if (response) {
      console.log(response.headers);
      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('refreshToken', response.headers.refreshtoken);
      localStorage.setItem('clientId', clientId);
      return (response.data.token);
    }
    return null;
  }

  async login({ email, password }) {
    const SERVICE_URI = uriConfig.uriService;
    console.log(`${SERVICE_URI}/api/v1/login | ${email} | ${password} `);
    const response = await axios.post(`${SERVICE_URI}/api/v1/login`, {}, {
      auth: {
        username: email,
        password
      }
    });
    console.log(`token ${response.data.token}`);
    localStorage.setItem('refreshToken', response.headers.refreshToken);
    if (!response.data.token) {
      return null;
    }
    return (response.data.token);
  }

  async refreshToken(refreshToken) {
    console.log(refreshToken);
    const SERVICE_URI = uriConfig.uriService;
    const response = await axios.post(`${SERVICE_URI}/api/v1/refreshToken`, {
      refreshToken,
    }).catch((error) => {
      console.log(error);
    });
    console.log(response);
    if (!response) {
      return null;
    }
    localStorage.setItem('accessToken', response.data.token);
    const user = await this.me(response.data.token);
    console.log(user);
    return user;
  }

  async register({ email, name, password }) {
    try {
      // Check if a user already exists
      let user = users.find((_user) => _user.email === email);

      if (user) {
        return null;
      }

      user = {
        id: generateResourceId(),
        avatar: null,
        email,
        name,
        password
      };

      users.push(user);

      const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN });

      return (accessToken);
    } catch (err) {
      console.error('[Auth Api]: ', err);
      return null;
    }
  }

  async me(accessToken) {
    try {
      const SERVICE_URI = uriConfig.uriService;
      console.log(`${SERVICE_URI}/api/v1/me | ${accessToken} `);
      const response = await axios.get(`${SERVICE_URI}/api/v1/me`, {
        headers: {
          authorization: `Bearer ${accessToken} `,
        }
      });
      console.log('response.data.data');
      console.log(response.data.data);
      const user = response.data.data;
      localStorage.setItem('displayName', response.data.data.name);
      if (!user) {
        return null;
      }
      return ({
        id: user.id,
        avatar: user.avatar,
        email: user.email,
        name: user.name
      });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      return null;
    }
  }
}

export const authApi = new AuthApi();
