import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppBar, Box, Link } from '@material-ui/core';
import { uriConfig } from '../config';
import gtm from '../lib/gtm';

export const SessionExpire = () => {
  const URI = uriConfig.uriV2;
  useEffect(() => {
    console.log(`accessToken: ${localStorage.getItem('accessToken')}`);
    console.log(`refreshToken: ${localStorage.getItem('refreshToken')}`);
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Session Expire | Vansales Dashboard</title>
      </Helmet>
      <AppBar
        elevation={0}
        sx={{ backgroundColor: 'background.paper' }}
      >
        -
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ textAlign: 'center', py: 9 }}>
          กรุณา
          <Link sx={{ px: 1 }} href={URI}>
            Login
          </Link>
          เพื่อเข้าใช้งาน
        </Box>
      </Box>
    </>
  );
};
