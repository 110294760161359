import 'simplebar/dist/simplebar.min.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { AuthProvider } from './contexts/jwt-auth-context';
import { SettingsProvider } from './contexts/settings-context';
import { App } from './app';

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <HashRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <AuthProvider>
              <Provider store={store}>
                <App />
                <Toaster position="bottom-right" />
              </Provider>
            </AuthProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </HashRouter>
    </HelmetProvider>
  </StrictMode>, document.getElementById('root')
);
